import React, { useEffect, useRef, useState, lazy } from "react";
import { HashRouter, Router, Redirect, Route, Switch } from "react-router-dom";
import { ResetCSS } from "@pancakeswap-libs/uikit";
import BigNumber from "bignumber.js";
import useEagerConnect from "hooks/useEagerConnect";
import useGetDocumentTitlePrice from "./hooks/useGetDocumentTitlePrice";
import GlobalStyle from "./style/Global";
import Menu from "./components/Menu";
import SuspenseWithChunkError from "./components/SuspenseWithChunkError";
import ToastListener from "./components/ToastListener";
import PageLoader from "./components/PageLoader";
import history from "./routerHistory";

// Route-based code splitting
const Home = lazy(() => import("./views/Home"));
const NotFound = lazy(() => import("./views/NotFound"));

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const App: React.FC = () => {
  useEffect(() => {
    console.warn = () => null;
  }, []);

  useEagerConnect();
  useGetDocumentTitlePrice();

  return (
    <HashRouter>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <ToastListener />
    </HashRouter>
  );
};

export default React.memo(App);
