import React, { useContext, Suspense } from "react";
import { Menu as UikitMenu } from "@pancakeswap-libs/uikit";
import { useWeb3React } from "@web3-react/core";
import { allLanguages } from "config/localisation/languageCodes";
import { LanguageContext } from "contexts/Localisation/languageContext";
import useTheme from "hooks/useTheme";
import useAuth from "hooks/useAuth";
import Landing from "components/custom/Landing";
import {
  // usePriceCakeBusd,
  useProfile,
} from "state/hooks";
import styled from "styled-components";
import Nav from "./Nav";

const Container = styled.div`
  margin-top: 80px;
  padding: 0;
`;

const MainContent = styled.div`
  min-height: calc(100vh - 121px);
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0;
  background-color: rgba(1, 1, 1, 0.4);
  border-top: 1px solid darkgrey;
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: white;
  margin: auto;
`;

const Menu = ({ children }) => {
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);
  const { isDark, toggleTheme } = useTheme();
  // const cakePriceUsd = usePriceCakeBusd();
  const { profile } = useProfile();
  return (
    <Container>
      <Nav />
      <MainContent>{children}</MainContent>
      <Footer>
        <FooterContent className="container" style={{ opacity: 0.7 }}>
          <div>
            This dApp was built with 💀
            by the wizards at{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://highstack.co/"
              style={{ color: "#add8e6" }}
            >
              Highstack.co
            </a>
          </div>
        </FooterContent>
      </Footer>
    </Container>
  );
};

export default Menu;
