import React, { useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import ConnectWalletButton from "components/ConnectWalletButton";
import GVC from 'assets/images/GVC.webp'


const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  // background: ${({ theme }) => theme.nav.background};
  height: 80px;
  z-index: 10;
  border-bottom: 0.1rem ${({ theme }) => theme.colors.borderColor} solid;
`;

const InnerNav = styled.div`
  width: 90%;
  height: 100%;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
`;
const NavElement = styled.li<{ currentPage: boolean }>`
  position: relative;
  font-size: 16px;
  font-weight: 800;
  color: ${({ theme, currentPage }) =>
    currentPage ? theme.colors.primary : "white"};
  display: inline-block;
  cursor: pointer;
  text-transform: capitalize;
  text-align: center;
  transition: transform 0.3s ease-out;

  &:hover {
    color: ${({ theme }) => theme.colors.borderColor};
  }
`;
const Content = styled.ul`
  flex: 6;
  list-style: none;
  display: none;
  margin: 0;
  justify-content: space-around;
  align-items: center;
  padding: 0 0 0 0;
  flex-direction: row;

  @media only screen and (min-width: 700px) {
    display: flex;
  }
`;
const CloseArea = styled.div`
  height: 100%;
  width: 100%;
`;
const CollapseContainer = styled.div<{ open: boolean }>`
  display: flex;
  transform: translate3d(${({ open }) => (open ? 0 : -150)}%, 0, 0);
  transition: 0.3s ease;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;
const CollapseContent = styled.ul`
  list-style: none;
  display: flex;
  height: 100%;
  width: 260px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 0;
  z-index: 9;

  border-bottom: 0.1rem ${({ theme }) => theme.colors.borderColor} solid;
  background: #3c0000;
  // backdrop-filter: blur(5px);

  z-index: 10;
  & > ${NavElement} {
    padding: 1rem 0;
    width: 100%;
    border-bottom: 1px solid white;
  }

  @media only screen and (min-width: 700px) {
    display: none;
  }
`;

const Logo = styled.img`
  flex: 1;
  height: 5rem;
  z-index: 5;
  padding 25px 0px;
`;
const HamburgerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 11;
  @media only screen and (min-width: 700px) {
    display: none;
  }
`;
const Bar = styled.div`
  height: 3px;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  z-index: 10;
`;
const Icon = styled.div<{ open: boolean }>`
  cursor: pointer;
  display: block;
  height: 30px;
  width: 35px;
  padding: 0 5px;
  transition: 0.1s ease;
  z-index: 1;

  &:hover {
    transition: 0.1s ease;
    padding: 0 2px;
  }
  & > ${Bar} {
    transition: 0.3s ease;
  }
  & > ${Bar}:nth-child(1) {
    transform: ${({ open }) =>
      open ? `translateY(14px) rotate(-45deg)` : `translateY(8px)`};
  }
  & > ${Bar}:nth-child(2) {
    opacity: ${({ open }) => (open ? `0` : `1`)};
    transform: translateY(12px);
  }
  & > ${Bar}:nth-child(3) {
    transform: ${({ open }) =>
      open ? `translateY(8px) rotate(45deg)` : `translateY(16px)`};
  }

  height: 30px;
  width: 35px;
`;

const LogoText = styled.span`
  color: white;
  font-weight: 800;
  margin: 10px;
`;
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DisableText = styled.div`
  :after {
    content: 'Coming Soon';
    font-size: 12px;
    font-weight:300;
    display:block;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  white-space: pre;
  opacity: 0.5;
  position: relative;
`;

function NavBar() {
  const [toggle, setToggle] = useState(false);
  const location = useLocation();
  return (
    <Nav>
      <InnerNav>
        <Link to="/">
          <LogoContainer>
            <Logo src={GVC} alt="Logo" /> <LogoText>Galaxy Villains</LogoText>
          </LogoContainer>
        </Link>

        <Content>
          <NavElement currentPage={location.pathname === "/"}>
            <Link to="/">Presale</Link>
          </NavElement>
          <NavElement currentPage={location.pathname === "/launchpad"}>
              <DisableText>Staking & Utilities</DisableText>
          </NavElement>
          <NavElement currentPage={false}>
            <a href="https://galaxyvillainstoken.com/">website</a>
          </NavElement>
          <ConnectWalletButton label="Connect Wallet" />
        </Content>
        <HamburgerContainer onClick={() => setToggle(!toggle)}>
          <Icon open={toggle}>
            <Bar />
            <Bar />
            <Bar />
          </Icon>
        </HamburgerContainer>
      </InnerNav>
      <CollapseContainer open={toggle}>
        <CollapseContent>
          <NavElement currentPage={location.pathname === "/"}>
            <Link to="/">Home</Link>
          </NavElement>
          <NavElement currentPage={location.pathname === "/launchpad"}>
              <DisableText>Launchpad</DisableText>
          </NavElement>
          
          <NavElement currentPage={false}>
            <a href="https://galaxyvillains.com/">Website</a>
          </NavElement>
          <ConnectWalletButton label="Connect Wallet" />
        </CollapseContent>
        <CloseArea onClick={() => setToggle(false)} />
      </CollapseContainer>
    </Nav>
  );
}

export default NavBar;
